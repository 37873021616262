import TooltipHelp from '@/components/settings/TooltipHelp';
import Checkbox from '@/components/utils/Checkbox';
import SearchBox from '@/components/utils/SearchBox';
import '@/utils/extensions';
import BootstrapVue from 'bootstrap-vue';
import PortalVue from 'portal-vue';
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import VueTippy, { TippyComponent } from 'vue-tippy';

// main scss file
import './styles/app.scss';

import posthog from 'posthog-js';
import setInterceptors from './api/interceptors';
import App from './App';
import i18n from './lang';
import router from './router';
import store from './store';
import { accountNumber, money } from './utils/filters';

Vue.use(PortalVue);
Vue.use(VueScrollTo);

Vue.use(VueTippy, { arrow: true });

// vue-bootstrap components
Vue.use(BootstrapVue);
Vue.component('Checkbox', Checkbox);
Vue.component('Tippy', TippyComponent);
Vue.component('SearchBox', SearchBox);
Vue.component('TooltipHelp', TooltipHelp);

Vue.filter('money', money);
Vue.filter('accountNumber', accountNumber);

if (process.env.NODE_ENV === 'production') {
  posthog.init('phc_a3joAYUp6aH23bBHM4E0Y8rMpcgqhsNVBU6QT7vvlt1',
    {
      api_host: 'https://eu.i.posthog.com',
      person_profiles: 'identified_only',
      enable_recording_console_log: true,
    });
}

// set interceptors for api calls
setInterceptors(router);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: (h) => h(App),
});
