<template>
  <div
    style="padding-top: 8px"
  >
    <div
      v-if="form"
      class="d-flex"
    >
      <div>
        <a
          :href="`/forms.html?payeeId=${payeeId}&formId=${form.id}`"
          class="link"
          target="_blank"
        >
          <i class="fas fa-arrow-up-right-from-square" />
          {{ $t('forms.open') }}
        </a>
      </div>
      <div class="ml-4">
        <a
          class="link"
          href="#"
          @click.prevent="copyLink"
        >
          <span v-if="!copySuccess">
            <i class="fas fa-clipboard pr-1" />
            {{ $t('forms.copyLink') }}
          </span>
          <span
            v-else
            class="text-success"
          >
            <i class="fas fa-check" /> {{ $t('forms.copySuccess') }}
          </span>
        </a>
      </div>
      <div class="ml-4">
        <a
          href="#"
          class="link"
          @click.prevent="clone"
        >
          <span v-if="!cloneSuccess">
            <i class="fa-solid fa-copy" />
            {{ $t('forms.clone') }}
          </span>
          <span
            v-else
            class="text-success"
          >
            <i class="fas fa-check" /> {{ $t('forms.cloneSuccess') }}
          </span>
        </a>
      </div>
    </div>
    <hr
      v-if="form"
      class="mt-2"
    >

    <label class="mb-1">
      {{ $t('settings.forms.formName') }}
    </label>
    <BFormInput
      v-model="name"
      class="mb-2"
      size="sm"
      :placeholder="$t('settings.forms.formName')"
    />

    <!-- <label class="mb-1">
      {{ $t('settings.forms.actionType') }}
    </label>
    <BFormSelect
      v-model="actionType"
      :disabled="!!form"
      :options="actionTypes"
      size="sm"
    /> -->

    <BFormCheckbox
      v-model="isActive"
      style="margin-left: -8px; margin-top: 15px;"
    >
      {{ $t('settings.forms.active') }}
    </BFormCheckbox>

    <hr v-if="form">
    <button
      v-if="form"
      class="btn btn-outline-primary btn-block btn-sm"
      @click="configure"
    >
      {{ $t('settings.forms.configure') }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    form: Object,
    pending: Boolean,
  },
  data: () => ({
    name: '',
    isActive: false,
    actionType: 'PayerCreate',
    copySuccess: false,
    cloneSuccess: false,
  }),
  computed: {
    ...mapGetters(['payeeId']),
  },
  methods: {
    ...mapActions([
      'updateFormDefinition',
      'updateFormDefinitionDetails',
      'createFormDefinition',
      'deleteFormDefinition',
      'cloneFormDefinition',
    ]),
    configure() {
      this.$router.push(`/payee/${this.payeeId}/settings/form/${this.form.id}`);
    },
    copyLink() {
      const link = `${window.location.origin}/forms.html?payeeId=${this.payeeId}&formId=${this.form.id}`;
      navigator.clipboard.writeText(link)
        .then(() => {
          this.copySuccess = true;
          setTimeout(() => {
            this.copySuccess = false;
          }, 2000);
        });
    },
    clone() {
      this.$emit('update:pending', true);

      this.cloneFormDefinition({
        params: {
          formId: this.form.id,
        },
      })
        .then(({ data }) => {
          this.$emit('add', data);
          this.$emit('open', data.id);
          this.cloneSuccess = true;
          setTimeout(() => {
            this.cloneSuccess = false;
          }, 2000);

          this.$emit('update:pending', false);
          this.$emit('refresh');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
    remove() {
      this.$emit('update:pending', true);
      this.deleteFormDefinition({
        params: {
          formId: this.form.id,
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('refresh');
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
    submit() {
      this.$emit('update:pending', true);
      this.updateFormDefinition({
        params: {
          formId: this.form.id,
        },
        data: {
          isActive: this.isActive,
          name: this.name,
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('refresh');
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
  },
  created() {
    if (this.form) {
      this.isActive = this.form.isActive;
      this.name = this.form.name;
    }
  },
};
</script>

<style>
.link {
    font-size: 12px;
}
</style>
