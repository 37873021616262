<template>
  <div id="app">
    <RouterView
      id="app-content"
    />
    <PortalTarget
      name="in-app"
      class="print-wrapper"
      multiple
    />
  </div>
</template>

<script>
import axios from '@/api/axios';
import Bowser from 'bowser';
import posthog from 'posthog-js';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('oidc', [
      'oidcIsAuthenticated',
      'oidcUser',
    ]),
  },
  watch: {
    oidcIsAuthenticated(v) {
      if (v) {
        posthog.identify(
          this.oidcUser.sub,
          { email: this.oidcUser.email },
        );

        axios.post('/ping', {
          isapp: false,
          origin: JSON.stringify(Bowser.parse(window.navigator.userAgent)),
          version: process.env.VUE_APP_VERSION || '?',
        });
      }
    },
  },
  created() {
    this.$store.dispatch('readFromStorage');
  },
};
</script>

<style scoped>

  #app-content {
    background-color: #efefef;
  }
</style>
