<template>
  <EditableList
    ref="list"
    :list="forms"
    :pending="pending"
    :form-add-title="$t('settings.forms.addForm')"
    :form-edit-title="$t('settings.forms.updateForm')"
    sticky
    @submit="$refs.form.submit()"
    @remove="$refs.form.remove()"
  >
    <template #listElement="{ row }">
      <div
        class="d-flex align-items-center justify-content-between w-100"
        style="overflow: hidden"
      >
        <span
          class="text-truncate"
        >
          {{ row.name }}
        </span>
        <div
          v-if="!row.isActive"
          v-tippy
          :content="$t('forms.inactive')"
        >
          <i
            class="fas fa-link-slash"
          />
        </div>
      </div>
    </template>

    <template #form="{edited}">
      <FormCreateForm
        v-if="edited === -1"
        ref="form"
        :pending.sync="pending"
        :form="getForm(edited)"
        @close="$refs.list.close()"
        @refresh="request"
      />
      <FormUpdateForm
        v-else
        ref="form"
        :pending.sync="pending"
        :form="getForm(edited)"
        @close="$refs.list.close()"
        @refresh="request"
        @add="forms.push($event)"
        @open="$refs.list.toggle($event)"
      />
    </template>
  </EditableList>
</template>

<script>
import EditableList from '@/components/settings/EditableList';
import { mapActions } from 'vuex';
import FormCreateForm from './FormCreateForm';
import FormUpdateForm from './FormUpdateForm';

export default {
  data: () => ({
    pending: false,
    forms: [],
  }),
  components: {
    EditableList,
    FormCreateForm,
    FormUpdateForm,
  },
  methods: {
    ...mapActions(['getFormDefinitions']),
    request() {
      this.getFormDefinitions()
        .then(({ data }) => {
          this.forms = data.sort((a, b) => a.name.localeCompare(b.name));
        });
    },
    getForm(id) {
      return this.forms.find((x) => x.id === id);
    },
  },
  created() {
    this.request();
  },
};
</script>
